import React from 'react';
import Link from './Link';
//import { LinkProps } from './types';
//import OpenNewIcon from '../Svg/Icons/OpenNew';



const LinkExternal = ({ children, ...props }) => {
  return (
    <Link external {...props}>
      {children}
      {/*<OpenNewIcon color='primary' ml='4px' />*/}
    </Link>
  );
};

export default LinkExternal;
