import React from 'react';

//import { Link as LinkUi } from 'react-router-dom';
import { useLocalization, LocalizedLink, LocalesList, } from 'gatsby-theme-i18n';
import getExternalLinkProps from '../../utils/getExternalLinkProps';
import { LinkProps } from './types';




const Link = ({ external, href, to, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {};
  if (external)
    return <a href={href} {...internalProps} {...props} />;
  else
    return <LocalizedLink to={to} {...internalProps} {...props} />;
};

Link.defaultProps = {
  //color: 'primary',
};

export default Link;
