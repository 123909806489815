import React from 'react';
import { useTranslation, } from 'react-i18next';

import { /*useModalProps,*/ } from 'react-simple-modal-provider';
import { LinkExternal, } from '../components/Link';
import CommonButton from '../components/CommonButton';
import { email, telegramUsername, } from '../configs';



export default function GetInTouchModalBody({ children, }) {
  const { t, } = useTranslation('modal-get-in-touch');

  return (
    <div className='moda-main'>
      {/*<div style={{ textAlign: 'center', }}>
        {t('Feel free to contact me')}
      </div>*/}
      <br />

      <div>
        <LinkExternal
          href={`https://t.me/${telegramUsername}`}
        >
          <CommonButton
            variant='gradient'
            size='static'
          >
            Telegram: @{telegramUsername}
          </CommonButton>
        </LinkExternal>
      </div>
      <br />

      <div>
        <LinkExternal
          href={`mailto:${email}`}
        >
          <CommonButton
            variant='gradient'
            size='static'
          >
            Email: {email}
          </CommonButton>
        </LinkExternal>
      </div>
    </div>
  );
}
