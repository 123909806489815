import { setIn } from 'immutable';
import * as AT from './actionTypes';



const initialState = {
  isOpen: false,
};


function mobileMenuReducer(state = initialState, action = {},) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return initialState;
    }

    case AT.TOGGLE: {
      /*if (state.isOpen == false) {
        document.body.style.setProperty("overflow", "hidden");
      } else {
        document.body.style.setProperty("overflow", "auto");
      }*/
      return setIn(state, ['isOpen'], !state.isOpen);
    }

    case AT.SET: {
      return setIn(state, ['isOpen'], payload);
    }

    default:
      return state;
  }
}

export default mobileMenuReducer;
