import React from 'react';
import { ThemeProvider, } from '../../src/theme';



// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, }) => {
  return (
    <ThemeProvider>
      {element}
    </ThemeProvider>
  );
}
