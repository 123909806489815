import './CommonButton.scss';

import React, { useState, useEffect, } from 'react';



export default function CommonButton({
  children, className, variant, size, active, onClick, ...rest
}) {
  const [clsName, setClsName] = useState('button');
  useEffect(() => {
    let clsName = `button`;
    if (className) {
      clsName += ` ${className}`;
    }
    if (variant) {
      clsName += ` button-${variant}`;
    }
    if (size) {
      clsName += ` button-size-${size}`;
    }
    setClsName(clsName);
  }, [className, variant, size]);

  return (
    <button
      className={clsName}
      active={active}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
