import { isProduction } from './constants';

export const fbqId = '?';

export const appVersion = '1.0.0';
export const appName = 'IVANNIKOV.PRO';


export const apiKey = '?';
//export const rootDomain = isProduction ? 'https://ivannikov.pro' : `http://${window.location.hostname}:3000`;
export const rootDomain = isProduction ? 'https://ivannikov.pro' : `http://localhost:3000`;
//export const apiDomain = isProduction ? 'https://api.ivannikov.pro' : `http://${window.location.hostname}:3100`;
export const apiDomain = isProduction ? 'https://api.ivannikov.pro' : `http://localhost:3100`;

export const phoneNumber = '+971 54 331 4282';
export const email = 'info@ivannikov.pro';
export const telegramUsername = 'ivannikovPro';

export const youtubeUrl = 'https://www.youtube.com/@ivannikov_pro';
//export const youtubeUrl = 'https://www.youtube.com/channel/UCicknIHIvrZSqL1ibouQIZg';
//

export const facebookUrl = `https://www.facebook.com/ivannikov.pro`;
export const instagramUrl = `https://www.instagram.com/ivannikov_pro/`;
export const twitterUser = `ivannikov_pro`;
export const twitterUrl = `https://twitter.com/${twitterUser}`;
export const telegramUrl = 'https://t.me/ivannikov_pro';
export const linkedInUrl = 'https://www.linkedin.com/in/an-ivannikov/';
export const mediumUrl = 'https://ivannikov-pro.medium.com/'; //'https://medium.com/@ivannikov.pro';
export const discordUrl = 'https://discord.gg/?';

export const roobiniumUrl = 'https://roobinium.io/?utm_source=ivannikovpro&utm_medium=site_bottom_link';

export const links = {
  en: {
    twitterUsername: 'ivannikov_pro',
    twitter: 'https://twitter.com/ivannikov_pro',
    facebook: 'https://www.facebook.com/ivannikov.pro',
    linkedin: 'https://www.linkedin.com/in/an-ivannikov/',

    medium: 'https://ivannikov-pro.medium.com/',
    youtube: 'https://www.youtube.com/@ivannikov_pro',
    instagram: 'https://www.instagram.com/ivannikov_pro/',
    tiktok: 'https://www.tiktok.com/@ivannikov_pro/',
    telegram: 'https://t.me/ivannikov_pro',

    flru: 'https://www.fl.ru/users/anivannikov/opinions/',

    roobinium: 'https://roobinium.io/',
  },
  ru: {
    twitterUsername: 'ivannikov_pro',
    twitter: 'https://twitter.com/ivannikov_pro',
    facebook: 'https://www.facebook.com/ivannikov.pro',
    linkedin: 'https://www.linkedin.com/in/an-ivannikov/',

    medium: 'https://ivannikov-pro.medium.com/', // TODO RU
    youtube: 'https://www.youtube.com/@ivannikov_pro', // TODO RU channel
    instagram: 'https://www.instagram.com/ivannikov_pro_ru/',
    tiktok: 'https://www.tiktok.com/@ivannikov_pro_ru/',
    telegram: 'https://t.me/ivannikov_pro_ru',

    flru: 'https://www.fl.ru/users/anivannikov/opinions/',

    roobinium: 'https://roobinium.io/ru/',
  },

  // TODO другие языки
};
