exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-blog-post-jsx": () => import("./../../../src/pages/blog/blog-post.jsx" /* webpackChunkName: "component---src-pages-blog-blog-post-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-curriculum-vitae-jsx": () => import("./../../../src/pages/curriculum-vitae.jsx" /* webpackChunkName: "component---src-pages-curriculum-vitae-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../../../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-fibonacci-assistant-telegram-bot-jsx": () => import("./../../../src/pages/portfolio/fibonacci-assistant-telegram-bot.jsx" /* webpackChunkName: "component---src-pages-portfolio-fibonacci-assistant-telegram-bot-jsx" */),
  "component---src-pages-portfolio-grabcoinclub-nft-jsx": () => import("./../../../src/pages/portfolio/grabcoinclub-nft.jsx" /* webpackChunkName: "component---src-pages-portfolio-grabcoinclub-nft-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-portfolio-poolex-ai-jsx": () => import("./../../../src/pages/portfolio/poolex-ai.jsx" /* webpackChunkName: "component---src-pages-portfolio-poolex-ai-jsx" */),
  "component---src-pages-portfolio-roborovski-nft-jsx": () => import("./../../../src/pages/portfolio/roborovski-nft.jsx" /* webpackChunkName: "component---src-pages-portfolio-roborovski-nft-jsx" */),
  "component---src-pages-portfolio-uls-token-jsx": () => import("./../../../src/pages/portfolio/uls-token.jsx" /* webpackChunkName: "component---src-pages-portfolio-uls-token-jsx" */),
  "component---src-pages-portfolio-world-millionaire-challenge-jsx": () => import("./../../../src/pages/portfolio/world-millionaire-challenge.jsx" /* webpackChunkName: "component---src-pages-portfolio-world-millionaire-challenge-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-products-notcoin-database-jsx": () => import("./../../../src/pages/products/notcoin-database.jsx" /* webpackChunkName: "component---src-pages-products-notcoin-database-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-services-forks-jsx": () => import("./../../../src/pages/services/forks.jsx" /* webpackChunkName: "component---src-pages-services-forks-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-services-telegram-bots-jsx": () => import("./../../../src/pages/services/telegram-bots.jsx" /* webpackChunkName: "component---src-pages-services-telegram-bots-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

