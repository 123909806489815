import React, { useEffect, } from 'react';
import Modal, { useModalState/*, useModal,*/ } from 'react-simple-modal-provider';
import GetInTouchModalBody from './GetInTouchModalBody';



export default function GetInTouchModal({ children, }) {
  const [isOpen, setOpen] = useModalState();
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isOpen]);

  const onClose = () => {
    setOpen(false);
  }


  /*const [data, setData] = useState(null);
  const asyncOpen = async () => {
    try {
      await new Promise((r) => setTimeout(r, 1000));
      setData({});
    } catch (error) { console.error(error); }
  };*/
  // const { open: openTemplateModal } = useModal('TemplateModal');

  return (
    <Modal
      id={'GetInTouchModal'}
      consumer={children}
      isOpen={isOpen}
      setOpen={setOpen}
      //asyncOpen={asyncOpen}
      draggable={false}
      duration={300}
      //animation={modalAnimation.scaleUp}
      width={300}
      height={250}
      radius={10}
    >
      <GetInTouchModalBody />
    </Modal>
  );
}
