import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import rehydrate from './rehydrate/reducers';
import theme from './theme/reducers';
import mobileMenu from './mobileMenu/reducers';


export const createRootReducer = () => {
  const rootReducer = combineReducers({
    rehydrate,
    theme: persistReducer({ key: 'theme', storage: storage, stateReconciler: autoMergeLevel1, }, theme),
    mobileMenu,
  });

  return rootReducer;
};

export default createRootReducer;
