import { applyMiddleware, compose, } from 'redux';
import { createLogger, } from 'redux-logger';
//import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { isProduction, } from '../constants';



export const sagaMiddleware = createSagaMiddleware();

export const createEnhancers = (isBrowser = false) => {
  let devTools = false;

  const middlewares = [];
  middlewares.push(sagaMiddleware);

  if (isBrowser) {
    //middlewares.push(thunk);

    if (!isProduction) {
      middlewares.push(createLogger({
        collapsed: true,
      }));

      devTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : null;
    }
  }

  const enhancers = [];
  enhancers.push(applyMiddleware(...middlewares));
  //if (devTools) enhancers.push(devTools);

  return compose(...enhancers);
}

export default createEnhancers;
