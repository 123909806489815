import React from 'react';
import { ModalProvider } from 'react-simple-modal-provider';
import GetInTouchModal from '../../src/modal/GetInTouchModal';



// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, }) => {
  const modals = [
    GetInTouchModal,
  ];

  return (<ModalProvider value={modals}>{element}</ModalProvider>);
}
