import React, { createContext, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as S from '../store/selectors';
import * as A from '../store/actions';



export const themeDefault = 'page_theme_dark';

export const themes = [themeDefault, 'page_theme_light',];

export const ThemeContext = createContext({
  theme: themeDefault,
  themes,
  toggleTheme: () => null,
});

export const ThemeProvider = ({ children, }) => {
  const [theme, setTheme] = useState(themeDefault);
  const toggleTheme = (nextTheme) => {
    setTheme(nextTheme);
    dispatch(A.theme.set(nextTheme));

    if (nextTheme === 'page_theme_dark') {
      document.body.classList.remove('page_theme_light');
      document.body.classList.add('page_theme_dark');
    } else if (nextTheme === 'page_theme_light') {
      document.body.classList.remove('page_theme_dark');
      document.body.classList.add('page_theme_light');
    }
  }

  const dispatch = useDispatch();
  const themeInStore = useSelector(S.theme.getTheme);
  useEffect(() => {
    if (themeInStore !== theme) {
      setTheme(themeInStore);
      dispatch(A.theme.set(themeInStore));
    }

    if (themeInStore === 'page_theme_dark') {
      document.body.classList.remove('page_theme_light');
      document.body.classList.add('page_theme_dark');
    } else if (themeInStore === 'page_theme_light') {
      document.body.classList.remove('page_theme_dark');
      document.body.classList.add('page_theme_light');
    }
  }, [theme, themeInStore]);


  return (
    <ThemeContext.Provider
      value={{ theme, themes, toggleTheme, }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
