module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"IVANNIKOV.PRO","short_name":"IVANNIKOV.PRO","description":"Custom development of Blockchain / Web3 / Crypto projects","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","lang":"en","display":"standalone","localize":[{"start_url":"/ru/","lang":"ru","name":"IVANNIKOV.PRO","short_name":"IVANNIKOV.PRO","description":"Заказная разработка блокчейн / веб3 / крипто проектов"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1dee58e1ee58b3f3a28d908e0c265fa4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-ZPD3FNRKQQ","GT-NNM8DV6","AW-16456714470"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://ivannikov.pro","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrica/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":96422747,"clickmap":true,"trackLinks":true,"accurateTrackBounce":true,"trackHash":true,"webvisor":true},
    },{
      plugin: require('../plugins/gatsby-theme-provider/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-redux-provider/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-react-simple-modal-provider/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/Users/ivannikov/work/ivannikov-pro/ivannikov.pro-project/ivannikov.pro-website/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/react-i18next","i18nextOptions":{"ns":["translation","404","header","footer","terms","privacy","about","curriculum-vitae","projects","blog","products","products-notcoin-database","products-hotcoin-database","services","portfolio","projects","modal-get-in-touch"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
