import { setIn } from 'immutable';
import * as AT from './actionTypes';
import { themeDefault, themes, } from '../../theme';



const initialState = {
  theme: themeDefault,
};


function mobileMenuReducer(state = initialState, action = {},) {
  const { type, payload } = action;

  switch (type) {
    case AT.CLEAR: {
      return initialState;
    }


    case AT.SET: {
      if (themes.includes(payload)) {
        return setIn(state, ['theme'], payload);
      }
      return state;
    }

    default:
      return state;
  }
}

export default mobileMenuReducer;
