import { put, select } from 'redux-saga/effects';
import * as A from './actions';
import * as S from './selectors';



const logLocation = 'sagas/rehydrate/sagas';

export default () => {

  const clearSaga = function* () {
    yield put(A.clear());
  }

  return {
    clearSaga,
  };
}

